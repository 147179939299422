import events from "dom-helpers/events";

import animateCSS from "./animations";
import { get } from "./utils";

import newParticipantTemplate from "../templates/participants/new.handlebars";
import participantStatusImage from "../../images/status_registered.png";
import remailFlashMessageTemplate from "../templates/participants/remail.handlebars";

export function newParticipantHandler() {
  const elem = document.getElementById("new_participant");
  if (elem !== null && typeof elem !== "undefined") {
    events.on(elem, "ajax:success", (event) => {
      let data = event.detail[0];
      data.participant.status_image = participantStatusImage;

      document.getElementById("wichtel_list").insertAdjacentHTML("beforeend", newParticipantTemplate(data.participant));
      animateCSS("#wichtel_list > li.hidden:last-child", "fadeIn");
      document.querySelectorAll("#new_participant span.error").forEach((elem) => {
        elem.parentNode.removeChild(elem);
      });
      event.target.reset();
    });
  }
}

export function newParticipantErrorHandler() {
  const elem = document.getElementById("new_participant");
  if (elem !== null && typeof elem !== "undefined") {
    events.on(elem, "ajax:error", (event) => {
      document.querySelectorAll("#new_participant span.error").forEach((elem) => {
        elem.parentNode.removeChild(elem);
      });

      let errors = get(["detail", 0, "errors"]) || {};

      for (const [key, value] of Object.entries(errors)) {
        let errorMsg = document.createElement("span");
        errorMsg.classList.add("error");
        errorMsg.innerHTML = value[0];

        document.getElementById(`participant_${key}`).after(errorMsg);
      }
    });
  }
}

export function removeParticipantHandler() {
  const elem = document.querySelector("#wichtel_list");
  if (elem !== null && typeof elem !== "undefined") {
    events.on(
      elem,
      "ajax:complete",
      events.filter("a.delete", (event) => {
        let xhr = event.detail[0];
        if (xhr !== null && typeof xhr !== "undefined" && 200 <= xhr.status < 300) {
          animateCSS(event.target.parentNode, "fadeOut", (node, _animationName) => {
            try {
              node.parentNode.removeChild(node);
            } catch (e) {
              if (!(e instanceof TypeError)) {
                throw e;
              }
            }
          });
        }
      })
    );
  }
}

export function editParticipantDetailsHandler() {
  document.querySelectorAll("#button-yes, #button-no").forEach((button) => {
    button.addEventListener("mouseenter", (event) => {
      const node = event.currentTarget;
      const state = node.id === "button-yes" ? "happy" : "sad";
      const imageEl = document.getElementById("wichtelillu-img");

      imageEl.dataset.className = imageEl.className;
      imageEl.className = state;
    });
    button.addEventListener("mouseleave", () => {
      const imageEl = document.getElementById("wichtelillu-img");

      imageEl.className = imageEl.dataset.className;
      imageEl.dataset.className = "";
    });
    button.addEventListener("click", (event) => {
      event.preventDefault();

      const targetEl = event.currentTarget;
      const state = targetEl.id === "button-yes" ? "confirmed" : "canceled";
      const imageEl = document.getElementById("wichtelillu-img");

      imageEl.dataset.className = imageEl.className;

      document.querySelectorAll("#button-yes, #button-no").forEach((elem) => {
        elem.className = "passive";
      });

      targetEl.classList.toggle("active");
      targetEl.classList.toggle("passive");

      document.getElementById("participant_status").value = state;
      return false;
    });
  });
}

export function remailParticipantHandler() {
  const elem = document.querySelector("ul.grouplist");
  if (elem !== null && typeof elem !== "undefined") {
    events.on(
      elem,
      "ajax:success",
      events.filter("a.remail", (event) => {
        let flashMessageContainer = document.getElementById("flashMessages");
        if (flashMessageContainer !== null && typeof flashMessageContainer !== "undefined") {
          flashMessageContainer.innerHTML = remailFlashMessageTemplate(event.detail[0]);
          flashMessageContainer.style.display = "block";
          animateCSS(flashMessageContainer, "fadeOut", (node, _animationName) => {
            node.style.display = "none";
          });
        }
      })
    );
  }
}
