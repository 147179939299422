// Entry point for the build script in your package.json
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

if (!Object.entries) {
  Object.entries = function (obj) {
    var ownProps = Object.keys(obj),
      i = ownProps.length,
      resArray = new Array(i); // preallocate the Array
    while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];

    return resArray;
  };
}

import "cookieconsent";
import "./components/mailcheck";
import Rails from "@rails/ujs";
import registerEventHandlers from "./components";

if (!window._rails_loaded) {
  Rails.start();
}

registerEventHandlers();
