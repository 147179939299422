import events from "dom-helpers/events";

import {
  newParticipantHandler,
  newParticipantErrorHandler,
  removeParticipantHandler,
  remailParticipantHandler,
  editParticipantDetailsHandler,
} from "./participants";
import { cancelPartyHandler, startDrawHandler } from "./parties";
import {
  showRelationshipForm,
  destroyRelationshipDataHandler,
  destroyRelationshipHandler,
  createRelationshipHandler,
  createRelationshipErrorHandler,
  relationshipSelectPartnerHandler,
} from "./relationships";
import { destroyUserHandler } from "./users";
import { showFlashMessages } from "./utils";
import { suggestEmailCorrectionsHandler } from "./mailcheck";

export default function registerEventHandlers() {
  events.on(document, "DOMContentLoaded", () => {
    newParticipantHandler();
    newParticipantErrorHandler();
    removeParticipantHandler();
    remailParticipantHandler();
    editParticipantDetailsHandler();

    cancelPartyHandler();
    startDrawHandler();

    showRelationshipForm();
    destroyRelationshipDataHandler();
    destroyRelationshipHandler();
    createRelationshipHandler();
    createRelationshipErrorHandler();
    relationshipSelectPartnerHandler();

    destroyUserHandler();

    showFlashMessages();
    suggestEmailCorrectionsHandler();
  });
}
