import Mailcheck from "mailcheck";
import events from "dom-helpers/events";

export function suggestEmailCorrectionsHandler() {
  document.querySelectorAll("input[data-mailcheck]").forEach((input) => {
    events.on(input, "blur", (event) => {
      Mailcheck.run({
        email: event.target.value,
        domains: [
          "aol.com",
          "aol.de",
          "aon.at",
          "arcor.de",
          "bluemail.ch",
          "bluewin.ch",
          "congstar.net",
          "email.de",
          "freenet.de",
          "gmail.com",
          "gmx.at",
          "gmx.ch",
          "gmx.com",
          "gmx.de",
          "gmx.net",
          "googlemail.com",
          "hispeed.ch",
          "hotmail.ch",
          "hotmail.com",
          "hotmail.de",
          "icloud.com",
          "live.at",
          "live.com",
          "live.de",
          "mac.com",
          "mail.de",
          "me.com",
          "msn.com",
          "netcologne.de",
          "o2online.de",
          "online.de",
          "onlinehome.de",
          "outlook.com",
          "outlook.de",
          "posteo.de",
          "rocketmail.com",
          "sunrise.ch",
          "t-online.de",
          "unitybox.de",
          "web.de",
          "yahoo.com",
          "yahoo.de",
          "ymail.com",
        ],
        secondLevelDomains: ["yahoo", "hotmail", "mail", "live", "outlook", "gmx", "aol"],
        suggested: (suggestion) => {
          let parentNode = event.target.parentNode;
          parentNode.querySelectorAll(".warning.warning--mailcheck").forEach((elem) => {
            elem.parentNode.removeChild(elem);
          });

          let warningMessage = document.createElement("span");
          warningMessage.classList.add("warning", "warning--mailcheck");
          warningMessage.innerHTML = `${event.target.dataset.mailcheck} <b>${suggestion.full}</b>`;

          parentNode.insertBefore(warningMessage, event.target.nextSibling);
        },
        empty: () => {
          let parentNode = event.target.parentNode;
          parentNode.querySelectorAll(".warning.warning--mailcheck").forEach((elem) => {
            elem.parentNode.removeChild(elem);
          });
        },
      });
    });
  });
}
