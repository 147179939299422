import animateCSS from "./animations";
import { get } from "./utils";

import events from "dom-helpers/events";

import createRelationshipTemplate from "../templates/relationships/create.handlebars";

export function destroyRelationshipDataHandler() {
  const elem = document.getElementById("wichtel_list");
  if (elem !== null && typeof elem !== "undefined") {
    events.on(
      elem,
      "ajax:beforeSend",
      events.filter("a.destroy", (event) => {
        let parent = event.target.closest("li[data-param]");
        event.detail[0].setRequestHeader("X-Wichtelomat-Relationship", `${parent.dataset.param}:${parent.dataset.partner}`);
      })
    );
  }
}

export function destroyRelationshipHandler() {
  const elem = document.getElementById("wichtel_list");
  if (elem !== null && typeof elem !== "undefined") {
    events.on(
      elem,
      "ajax:success",
      events.filter("a.destroy", (event) => {
        let sourceElement = event.target.closest("li");
        let targetElem = document.querySelector(`#wichtel_list > li[data-param="${sourceElement.dataset.partner}"]`);
        delete sourceElement.dataset.partner;
        animateCSS(sourceElement.querySelector(".partner-information"), "fadeOut", (node, _animationName) => {
          try {
            node.parentNode.removeChild(node);
          } catch (e) {
            if (!(e instanceof TypeError)) {
              throw e;
            }
          }
        });

        delete targetElem.partner;
        animateCSS(targetElem.querySelector(".partner-information"), "fadeOut", (node, _animationName) => {
          try {
            node.parentNode.removeChild(node);
          } catch (e) {
            if (!(e instanceof TypeError)) {
              throw e;
            }
          }
        });

        document.querySelectorAll("#relationship_relationship_source, #relationship_relationship_target").forEach((elem) => {
          elem.appendChild(new Option(sourceElement.dataset.name, sourceElement.dataset.param));
          elem.appendChild(new Option(targetElem.dataset.name, targetElem.dataset.param));
        });

        if (document.querySelectorAll("#relationship_relationship_source > option").length > 2) {
          animateCSS("#new_relationship", "fadeIn");
        }
      })
    );
  }
}

export function createRelationshipHandler() {
  const elem = document.getElementById("new_relationship");
  if (elem !== null && typeof elem !== "undefined") {
    events.on(elem, "ajax:success", (event) => {
      if (!Array.isArray(event.detail) || !event.detail.length) {
        elem.reset();
        return;
      }
      let relationship = event.detail[0].relationship;

      let sourceElem = document.querySelector("#relationship_relationship_source option:checked");
      let targetElem = document.querySelector("#relationship_relationship_target option:checked");

      let sourceData = {
        partner: sourceElem.innerHTML,
        partner_id: sourceElem.value,
        id: targetElem.value,
        path: relationship.path,
      };

      let targetData = {
        partner: targetElem.innerHTML,
        partner_id: targetElem.value,
        id: sourceElem.value,
        path: relationship.path,
      };

      let sourceItem = document.querySelector(`#wichtel_list > li[data-param="${sourceData.id}"]`);
      let targetItem = document.querySelector(`#wichtel_list > li[data-param="${targetData.id}"]`);

      sourceItem.insertAdjacentHTML("beforeend", createRelationshipTemplate(sourceData));
      sourceItem.dataset.partner = sourceData.partner_id;

      targetItem.insertAdjacentHTML("beforeend", createRelationshipTemplate(targetData));
      targetItem.dataset.partner = targetData.partner_id;

      elem.querySelectorAll("span.error").forEach((node) => {
        try {
          node.parentNode.removeChild(node);
        } catch (e) {
          if (!(e instanceof TypeError)) {
            throw e;
          }
        }
      });

      elem.querySelectorAll("option").forEach((option) => {
        if (option.value === sourceData.partner_id || option.value === targetData.partner_id) {
          option.parentNode.removeChild(option);
        }
      });

      if (document.querySelectorAll("#relationship_relationship_source > option").length <= 2) {
        animateCSS(elem, "fadeOut", (node, _animationName) => {
          node.style.display = "none";
        });
      }

      elem.reset();
    });
  }
}

export function createRelationshipErrorHandler() {
  const elem = document.getElementById("new_relationship");
  if (elem !== null && typeof elem !== "undefined") {
    events.on(elem, "ajax:error", (event) => {
      let errors = get(["detail", 0, "errors"]) || {};

      for (const [key, value] of Object.entries(errors)) {
        let errorMsg = document.createElement("span");
        errorMsg.classList.add("error");
        errorMsg.innerHTML = value[0];

        document.getElementById(`relationship_${key}`).after(errorMsg);
      }
    });
  }
}

export function relationshipSelectPartnerHandler() {
  document.querySelectorAll("#new_relationship select").forEach((elem) => {
    events.on(elem, "change", (event) => {
      let select = event.target;
      let selected = select.querySelector("option:checked");

      document.querySelectorAll(`#new_relationship select:not([id="${select.id}"]) > option`).forEach((option) => {
        if (option.value !== "") {
          option.disabled = option.value === selected.value;
        }
      });
    });
  });
}

export function showRelationshipForm() {
  const elem = document.querySelector("form.new_relationship");
  const optionsList = document.querySelectorAll("#relationship_relationship_source > option");
  if (elem !== null && elem !== undefined && optionsList.length > 2) {
    elem.style.display = "block";
  }
}
