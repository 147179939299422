export default function animateCSS(element, animationName, callback) {
  const node = element instanceof Element ? element : document.querySelector(`${element}`);
  let show = animationName === "fadeIn";
  let display = window.getComputedStyle(node).display;

  if (display === "none" && show) {
    node.dataset.display = display;
    node.style.display = "block";
  }
  node.classList.add("animated", animationName);

  const handleAnimationEnd = () => {
    node.classList.remove("animated", animationName);
    node.removeEventListener("animationend", handleAnimationEnd);

    if (!show) {
      node.style.display = node.dataset.display;
    }
    if (typeof callback == "function") {
      callback(node, animationName);
    }
  };

  node.addEventListener("animationend", handleAnimationEnd);
}

export function fadeToggleListener(buttonSelector, toggleSelector) {
  const buttonEl = document.querySelector(buttonSelector);
  if (buttonEl !== null && buttonEl !== undefined) {
    buttonEl.addEventListener("click", (event) => {
      event.preventDefault();

      let elem = document.querySelector(toggleSelector);
      let isVisible = !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);
      let animationName = isVisible ? "fadeOut" : "fadeIn";

      animateCSS(elem, animationName);
    });
  }
}

export function fadeToggleButtons(event) {
  event.preventDefault();
  event.currentTarget.parentNode.querySelectorAll("a.bigbutton:not(:first-child)").forEach((elem) => {
    let isVisible = !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);
    let animationName = isVisible ? "fadeOut" : "fadeIn";
    animateCSS(elem, animationName, (node, animationName) => {
      if (animationName === "fadeOut") {
        node.classList.add("disabled");
      }
    });
    elem.classList.remove("disabled");
  });
}
