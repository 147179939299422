import animateCSS from "./animations";

export function get(path, obj) {
  return path.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), obj);
}

export function showFlashMessages() {
  const node = document.getElementById("flashMessages");
  if (node !== null && typeof node !== "undefined" && node.hasChildNodes()) {
    Array.from(node.childNodes).forEach((elem) => {
      if (elem.nodeType === Node.ELEMENT_NODE) {
        node.style.removeProperty("display");
      }
    });

    animateCSS("#flashMessages", "fadeOut", (node, _animatonName) => {
      node.style.display = "none";
    });
  }
}
