import { fadeToggleListener, fadeToggleButtons } from "./animations";

export function cancelPartyHandler() {
  document.querySelectorAll("#cancelparty, #ohno").forEach((button) => {
    button.addEventListener("click", fadeToggleButtons);
  });
}

export function startDrawHandler() {
  fadeToggleListener("#readyandgo", "#reallybuttonset");
  fadeToggleListener("#button-draw", "#pleasewait");
  fadeToggleListener("#button-abort", "#reallybuttonset");
}
